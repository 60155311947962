<template>
    <zw-sidebar @shown="shown" :title="$t('multichannel.settings.store.title')">
        <validation-observer tag="div" ref="observer">
            <b-overlay :show="loading" no-wrap></b-overlay>
            <div v-if="!loading">
                <b-row>
                    <b-col cols="12">
                        <zw-input-group v-model="form.name"
                                        name="name"
                                        :label-prefix="labelPrefix"
                                        validate="required"
                        ></zw-input-group>
                    </b-col>

                    <b-col cols="12" v-if="payload.api_type == 'amazon'">
                        <zw-input-group v-model="form.code"
                                        name="code"
                                        :label-prefix="labelPrefix"
                                        validate="required"
                        ></zw-input-group>
                        <zw-input-group v-model="form.options.refresh_token"
                                        name="refresh_token"
                                        :label-prefix="labelPrefix"
                                        validate="required"
                        ></zw-input-group>
                    </b-col>
                    <b-col cols="6">
                        <zw-select-group v-model="form.locale"
                                         :options="getLocales()"
                                         name="language"
                                         :label-prefix="labelPrefix"
                                         validate="required"
                                         value-field="code"
                                         text-field="name"
                        ></zw-select-group>
                    </b-col>
                    <b-col cols="6">
                        <zw-input-group v-model="form.currency"
                                        name="currency"
                                        :label-prefix="labelPrefix"
                                        validate="required"
                        ></zw-input-group>
                    </b-col>
                    <b-col cols="6">
                        <zw-input-group v-model="form.currency_short"
                                        name="currency_short"
                                        :label-prefix="labelPrefix"
                                        validate="required"
                        ></zw-input-group>
                    </b-col>
                    <b-col cols="6">
                            <zw-select-group v-model="form.default_vat"
                                             :options="getVats() | optionsVL"
                                             name="default_vat"
                                             :label-prefix="labelPrefix"
                            ></zw-select-group>
                    </b-col>

                    <b-col cols="3">
                        <zw-switch-group v-model="form.use_profile"
                                         name="use_profile"
                                         :label-prefix="labelPrefix"
                        ></zw-switch-group>
                    </b-col>
                    <b-col cols="3">
                        <zw-switch-group v-model="form.create_product"
                                         name="create_product"
                                         :label-prefix="labelPrefix"
                        ></zw-switch-group>
                    </b-col>
                    <b-col cols="3" v-if="payload.api_type == 'shopify'">
                        <zw-switch-group v-model="form.options.sync_payment_status"
                                         name="sync_payment_status"
                                         :label-prefix="labelPrefix"
                        ></zw-switch-group>
                    </b-col>
                    <b-col cols="3" v-if="payload.api_type == 'amazon'">
                        <zw-switch-group v-model="form.options.include_prime_logo_to_return"
                                         name="include_prime_logo_to_return"
                                         :label-prefix="labelPrefix"
                        ></zw-switch-group>
                    </b-col>
                    <b-col cols="3" v-if="payload.api_type == 'shopify'">
                        <zw-switch-group v-model="form.use_api_order_number"
                                         name="use_api_order_number"
                                         @input="(val)=>{updateUseApiOrderNumber(val)}"
                                         :label-prefix="labelPrefix"
                        ></zw-switch-group>
                    </b-col>
                </b-row>
                <template v-if="form.use_profile != 1">
                    <b-row>
                        <b-col cols="2">
                            <zw-input-group v-model="form.invoice_prefix"
                                            name="invoice_prefix"
                                            :label-prefix="labelPrefix"
                            ></zw-input-group>
                        </b-col>
                        <b-col cols="2">
                            <zw-input-group v-model="form.invoice_separator_1"
                                            name="invoice_separator_1"
                                            :maxLength="2"
                                            :label-prefix="labelPrefix"
                            ></zw-input-group>
                        </b-col>
                        <b-col cols="3">
                            <b-row>
                                <b-col cols="3">
                                    <zw-switch-group v-model="form.use_year"
                                                     name="use_year"
                                                     @input="updateInvoiceNumberPlaceholder"
                                                     :label-prefix="labelPrefix"
                                    ></zw-switch-group>
                                </b-col>
                                <b-col cols="9">
                                    <zw-input-group :value="form.year"
                                                    v-if="form.use_year == 1"
                                                    name="year"
                                                    :label-prefix="labelPrefix"
                                                    :readonly="true"
                                    ></zw-input-group>
                                </b-col>
                            </b-row>
                        </b-col>
                        <b-col cols="2">
                            <zw-input-group v-model="form.invoice_separator_2"
                                            name="invoice_separator_2"
                                            :maxLength="2"
                                            :label-prefix="labelPrefix"
                            ></zw-input-group>
                        </b-col>
                        <b-col cols="2">
                            <zw-input-group v-model="form.invoice_number"
                                            v-if="payload.api_type != 'shopify' || (payload.api_type == 'shopify' && !form.use_api_order_number)"
                                            name="invoice_number"
                                            type="number"
                                            :label-prefix="labelPrefix"
                            ></zw-input-group>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col cols="12">
                            <zw-input-group v-model="form.invoice_number_placeholder"
                                            name="invoice_number_placeholder"
                                            :label-prefix="labelPrefix"
                                            :readonly="true"
                            ></zw-input-group>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col cols="6">
                            <zw-file-group v-model="form.invoice_background"
                                           name="invoice_background"
                                           :label-prefix="labelPrefix"
                                           document-type="background"
                                           accept=".pdf"
                            ></zw-file-group>
                        </b-col>
                        <b-col cols="6">
                            <zw-file-group v-model="form.invoice_background_2"
                                           name="invoice_background_2"
                                           :label-prefix="labelPrefix"
                                           document-type="background"
                                           accept=".pdf"
                            ></zw-file-group>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col cols="6">
                            <zw-file-group v-model="form.invoice_background_a5"
                                           name="invoice_background_a5"
                                           :label-prefix="labelPrefix"
                                           document-type="background"
                                           accept=".pdf"
                            ></zw-file-group>
                        </b-col>
                        <b-col cols="6">
                            <zw-file-group v-model="form.invoice_background_a5_2"
                                           name="invoice_background_a5_2"
                                           :label-prefix="labelPrefix"
                                           document-type="background"
                                           accept=".pdf"
                            ></zw-file-group>
                        </b-col>
                    </b-row>
                </template>
                <b-row>
                    <b-col cols="6">
                        <zw-file-group v-model="form.combine_background"
                                       name="combine_background"
                                       :label-prefix="labelPrefix"
                                       document-type="background"
                                       accept=".pdf"
                        ></zw-file-group>
                    </b-col>
                    <b-col cols="6">
                        <zw-file-group v-model="form.combine_2_background"
                                       name="combine_2_background"
                                       :label-prefix="labelPrefix"
                                       document-type="background"
                                       accept=".pdf"
                        ></zw-file-group>
                    </b-col>
                    <b-col cols="6">
                        <zw-multiselect-group v-model="form.combine_list_1"
                                              :options="getDocumentsList()"
                                              name="combine_list_1"
                                              :label-prefix="labelPrefix"
                        ></zw-multiselect-group>
                    </b-col>
                    <b-col cols="6">
                        <zw-multiselect-group v-model="form.combine_list_2"
                                              :options="getDocumentsList()"
                                              name="combine_list_2"
                                              :label-prefix="labelPrefix"
                        ></zw-multiselect-group>
                    </b-col>
                </b-row>

                <b-row>
                    <b-col sm="12" class="text-sm-right">
                        <b-button block @click="onSubmit()" variant="primary">
                            {{ $t('common.button.save') }}
                        </b-button>
                    </b-col>
                </b-row>
            </div>
        </validation-observer>
    </zw-sidebar>
</template>

<script>
import {mapGetters} from 'vuex'
import commonSave from '@/bundles/erika_common_bundle/mixins/common-save'

export default {
    name: 'Store-modal',
    mixins: [commonSave],
    data() {
        return {
            returnApiOrderNumber: false,
            loading: true,
            payload: {},
            callback: null,
            defaultForm: {
                name: '',
                value: '',
                combine_list_1: [],
                combine_list_2: [],
                options: {},
            },
            form: {},
            labelPrefix: 'multichannel.settings.store.label.',
        }
    },
    methods: {
        ...mapGetters('CommonData', ['getLocales', 'getDocuments','getVats']),
        ...mapGetters('Multichannel', ['getStore']),
        updateInvoiceNumberPlaceholder() {
            if (this.form.use_year == 1) {
                this.form.invoice_number_placeholder = this.form.invoice_number_placeholder.replace(/\{separator_1\}\{separator_2\}/g, `{separator_1}{year}{separator_2}`);
            } else {
                this.form.invoice_number_placeholder = this.form.invoice_number_placeholder.replace(/\{year\}/g, '');
            }
        },
        updateUseApiOrderNumber(val) {
            if (!this.returnApiOrderNumber) {
                this.$confirm(
                    {
                        message: this.$t('common.confirmations.change'),
                        button: {
                            no: this.$t('common.button.no'),
                            yes: this.$t('common.button.yes')
                        },
                        callback: confirm => {
                            if (!confirm) {
                                this.$set(this.form, 'use_api_order_number', +!val)
                                this.returnApiOrderNumber = true;
                            }
                        }
                    }
                )
            } else {
                this.returnApiOrderNumber = false
            }
        },
        shown() {
            if (this.payload.id) {
                const store = this.$store.dispatch('Multichannel/fetchStore', this.payload.id)

                Promise.all([store])
                    .then(() => {
                        this.form = {
                            ...this.defaultForm,
                            ...JSON.parse(JSON.stringify(this.getStore()))
                        }
                        this.form.combine_list_1 = this.form.combine_list_1 ? this.form.combine_list_1.split(',') : []
                        this.form.combine_list_2 = this.form.combine_list_2 ? this.form.combine_list_2.split(',') : []
                        if (this.form.options == null) {
                            this.form.options = {}
                        }
                        this.form.year = new Date().getFullYear()
                        this.$refs['observer'].reset()
                    })
                    .finally(() => {
                        this.loading = false
                    })
            } else {
                this.form = {
                    ...this.defaultForm,
                }
                this.loading = false
            }
        },
        getDocumentsList() {
            return this.getDocuments().map(document => {
                return this.$t('invoices.document.' + document)
            })
        },
        onSubmit() {
            this.$refs['observer'].validate().then(valid => {
                if (valid) {
                    this.loading = true

                    if (this.payload.channel_id) {
                        this.form.api_source_id = this.payload.channel_id
                    }

                    this.$store.dispatch('Multichannel/saveChannel', this.form)
                        .then((response) => {
                            this.commonAfterSave(response)
                        })
                        .catch(errors => {
                            this.$refs['observer'].setErrors(errors)
                            this.showValidationError()
                        })
                        .finally(() => {
                            this.loading = false;
                        })
                } else {
                    this.showValidationError()
                }
            })
        },
    }
}
</script>